.auto_content[data-v-ae903d00] {
  margin: 0 0 5px;
}
.top_select[data-v-ae903d00] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.top_select .left_select[data-v-ae903d00] {
  display: flex;
  align-items: center;
}
.item_icon[data-v-ae903d00] {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.item_icon i[data-v-ae903d00] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 2px;
}
.item_icon p[data-v-ae903d00] {
  display: inline-block;
  font-size: 13px;
  color: #333;
}
.div_p[data-v-ae903d00] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.div_p p[data-v-ae903d00] {
  height: 24px;
  line-height: 24px;
}
.icon-weixintuisong[data-v-ae903d00] {
  color: #15ba11;
  font-size: 13px;
  margin-left: 8px;
  cursor: pointer;
  position: relative;
  top: 2px;
}