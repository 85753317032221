.import-bank[data-v-a6249a22] {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.item[data-v-a6249a22] {
  background-color: #f5f6fa;
  border: 1px dashed #d9dbdc;
  text-align: center;
  padding: 15px 20px;
  border-radius: 4px;
}
.item img[data-v-a6249a22] {
  width: 45px;
  height: auto;
  margin-bottom: 10px;
}